import axios from "axios";
import type { AxiosPromise, RawAxiosRequestConfig, AxiosError, AxiosResponse } from "axios";
import PQueue from "p-queue";

import type { AuthenticatorFunction } from "./types";

export const defaultAxiosSettings = {
    withCredentials: true,
    headers: {
        "Content-Type": "application/json", // Setting MIME type to JSON
    },
};

export class LowLevel {
    private readonly _rootUrl: string;

    /**
     * Request queue in which the outgoing requests to the API are placed.
     */
    private readonly _requestQueue = new PQueue({
        concurrency: 2,
    });

    public constructor(rootUrl: string) {
        if (!rootUrl) {
            throw new Error("API root URL is empty!");
        }
        this._rootUrl = rootUrl.substring(rootUrl.length - 1, rootUrl.length) === "/" ? rootUrl : rootUrl + "/";
    }

    private readonly _urlFor = (name: string): string => new URL(name, this._rootUrl).href;

    private _authenticator: AuthenticatorFunction = () =>
        Promise.reject(new Error("No authenticator function is configured for the API"));

    public readonly sendGetRequest = (url: string, config?: RawAxiosRequestConfig): AxiosPromise<unknown> => {
        return this._sendAPIRequest(url, {
            method: "get",
            ...config,
        });
    };

    public readonly sendPostRequest = (
        url: string,
        data?: unknown,
        config?: RawAxiosRequestConfig
    ): AxiosPromise<unknown> => {
        return this._sendAPIRequest(url, {
            data,
            method: "post",
            ...config,
        });
    };

    public readonly sendPutRequest = (
        url: string,
        data?: unknown,
        config?: RawAxiosRequestConfig
    ): AxiosPromise<unknown> => {
        return this._sendAPIRequest(url, {
            data,
            method: "put",
            ...config,
        });
    };

    public readonly sendDeleteRequest = (url: string, config?: RawAxiosRequestConfig): AxiosPromise<unknown> => {
        return this._sendAPIRequest(url, {
            method: "delete",
            ...config,
        });
    };

    private readonly _sendAPIRequest = (url: string, config?: RawAxiosRequestConfig): AxiosPromise<unknown> => {
        const tryAgainIfAuthRequiredOrFormatError = async (error: AxiosError) => {
            const { response } = error;
            if (response && response.status === 401 && url !== "auth/login") {
                await this._authenticator();
                return this._sendAPIRequest(url, config);
            } else {
                // If the response has a user-readable message, throw a new error
                // with that message so it is shown on the UI. Otherwise just
                // re-throw the error.
                if (
                    response &&
                    typeof response.data === "object" &&
                    response.data !== null &&
                    "message" in response.data
                ) {
                    const message = response.data.message;
                    if (typeof message === "object" && message !== null) {
                        throw new Error(JSON.stringify(message));
                    }
                    throw new Error(String(message));
                } else {
                    throw error;
                }
            }
        };

        // the catch() stanza below must be added _outside_ the task added to the
        // queue (and not as part of it; otherwise the task would stay in the
        // request queue until the authentication finishes, potentially blocking
        // other tasks that do not require authentication, or even causing a
        // complete deadlock)
        return this._requestQueue
            .add<AxiosResponse<unknown>>(() => this._sendAPIRequestNow(url, config), {
                throwOnTimeout: true, // This option is necessary to give proper type by the p-queue
            })
            .catch<AxiosResponse<unknown>>(tryAgainIfAuthRequiredOrFormatError);
    };

    private readonly _sendAPIRequestNow = (url: string, config?: RawAxiosRequestConfig): AxiosPromise<unknown> => {
        console.log("CALL ", this._urlFor(url));

        const arg = {
            ...defaultAxiosSettings,
            ...config,
        };
        if (arg.headers === undefined) {
            arg.headers = {
                "Content-Type": "application/json", // Setting MIME type to JSON
            };
        }

        return axios(this._urlFor(url), arg);
    };

    public readonly setAuthenticator = (value: AuthenticatorFunction): void => {
        this._authenticator = value;
    };
}
