import type { AxiosPromise } from "axios";

import type { LowLevel } from "./lowlevel";
import type { IAuthenticatedUserInfo } from "./types";

export class Auth {
    private readonly _lowLevel: LowLevel;

    public constructor(lowLevel: LowLevel) {
        this._lowLevel = lowLevel;
    }

    public readonly callForLogin = (user: string, password: string): AxiosPromise<unknown> => {
        return this._lowLevel.sendPostRequest("auth/login", { user, password });
    };

    public readonly callForLogout = (): AxiosPromise<unknown> => {
        return this._lowLevel.sendPostRequest("auth/logout", {});
    };

    public readonly callForGetAuthenticatedUserName = async (): Promise<string> => {
        try {
            const response = await this.callForGetAuthenticationInfo();
            return response.login;
        } catch {
            return "";
        }
    };

    public readonly callForGetAuthenticationInfo = async (): Promise<IAuthenticatedUserInfo> => {
        const response = await this._lowLevel.sendGetRequest("auth/user");
        return response.data as IAuthenticatedUserInfo;
    };
}
