import { makeAutoObservable } from "mobx";
import { Deferred } from "ts-deferred";

class AuthStore {
    isLoggedIn = false;

    public authInProgress: Deferred<string> | undefined = undefined;

    constructor() {
        makeAutoObservable(this);
    }

    /**
     * Authentication is in progress.
     */
    public isAuthenticating = (): boolean => {
        return this.authInProgress !== undefined;
    };

    public login = () => {
        this.isLoggedIn = true;
    };

    public logout = () => {
        this.isLoggedIn = false;
    };

    public authenticate = (): Promise<string> => {
        this.authInProgress = new Deferred<string>();
        return this.authInProgress.promise;
    };
}

export const authStore = new AuthStore();
