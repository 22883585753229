import React, { useState } from "react";
import { authStore } from "./auth";

interface Props {
    callLogin: (user: string, password: string) => Promise<unknown>;
}

const LoginDialog: React.FC<Props> = ({ callLogin }) => {
    // State for the username and password inputs
    const [username, setUsername] = useState("");
    const [password, setPassword] = useState("");

    const [loginError, setLoginError] = useState<string | null>(null);

    const handleSubmit = async (event: React.FormEvent) => {
        event.preventDefault();
        // Call the callLogin function with the username and password
        try {
            await callLogin(username, password);
            authStore.login();
            authStore.authInProgress?.resolve(username);
            console.log("Login succeeded");
            // Clear form fields on successful login
            setUsername("");
            setPassword("");
        } catch (e) {
            authStore.logout();
            authStore.authInProgress?.reject(e);
            console.log("Login failed");
            // Set login error message
            setLoginError("Login failed. Please check your username and password.");
        }
    };

    return (
        <div className="login-dialog">
            <form onSubmit={handleSubmit}>
                <div>Traffic Prediction</div>
                <label htmlFor="username">Username</label>
                <input
                    type="text"
                    id="username"
                    required
                    value={username}
                    onChange={(e) => setUsername(e.target.value)}
                />
                <label htmlFor="password">Password</label>
                <input
                    type="password"
                    id="password"
                    required
                    value={password}
                    onChange={(e) => setPassword(e.target.value)}
                />
                <button type="submit">Login</button>
                {loginError && <div style={{ color: "red", marginTop: "10px" }}>{loginError}</div>}
            </form>
        </div>
    );
};

export default LoginDialog;
