import React, { useCallback, useState, useRef, useEffect } from "react";
import { formatDateS } from "~/dateutil";
import { findIndexAfterGivenTimestamp } from "~/dateutil/BinarySearch";

// Define TypeScript interfaces for the props
interface TimeInterval {
    startTime: string; // Assuming the times are strings, adjust if necessary (e.g., Date objects)
    endTime: string;
}

interface Series {
    name: string;
    values: number[]; // Array of numeric values
}

interface TimeSeriesTableProps {
    intervals: TimeInterval[];
    seriesList: Series[];
    selectedDate: string | null;
}

export function TimeSeriesTable({ intervals, seriesList, selectedDate }: TimeSeriesTableProps) {
    const [sortDirection, setSortDirection] = useState<"asc" | "desc">("asc");
    const [inputDateTime, setInputDateTime] = useState(selectedDate || "");
    const scrollContainerRef = useRef<HTMLDivElement>(null);

    // Calculate duration for each interval
    const calculateDuration = (start: string, end: string) => {
        const startTime = new Date(start);
        const endTime = new Date(end);
        return (endTime.getTime() - startTime.getTime()) / 1000; // Duration in seconds
    };

    // TODO use callback
    const sort = useCallback(() => {
        setSortDirection(sortDirection === "asc" ? "desc" : "asc");
    }, [sortDirection]);

    const scrollToRow = (rowIndex: number) => {
        const container = scrollContainerRef.current;
        if (container) {
            const rowToScrollTo = container.querySelector(`table tr:nth-child(${rowIndex + 1})`);
            if (rowToScrollTo) {
                const topPosition = (rowToScrollTo as HTMLTableRowElement).offsetTop;
                container.scrollTo({ top: topPosition, behavior: "smooth" });
            }
        }
    };

    const handleNavRequest = useCallback(
        (dateTime: string) => {
            console.log("Navigating to date:", dateTime);
            const i = findIndexAfterGivenTimestamp(intervals, dateTime); // Assume intervals and findIndexAfterGivenTimestamp are defined
            console.log("Index to scroll to:", i);
            if (sortDirection === "asc") {
                scrollToRow(intervals.length - i - 1);
            } else {
                scrollToRow(i);
            }
        },
        [scrollToRow]
    );

    useEffect(() => {
        setInputDateTime(selectedDate || "");
        console.log("selectedDate changed:", selectedDate);
    }, [selectedDate]);

    return (
        <div style={{ display: "flex", flexDirection: "column", flexGrow: 1, minHeight: 0 }}>
            <div>
                <input
                    type="datetime-local"
                    value={inputDateTime}
                    onChange={(e) => setInputDateTime(e.target.value)}
                    step="1" // Allows for second precision
                />
                <button onClick={() => handleNavRequest(inputDateTime)}>Go to Date</button>
            </div>
            <div className="table-log-container" ref={scrollContainerRef} style={{ height: "100%" }}>
                <table className="table-log">
                    <thead>
                        <tr>
                            <th onClick={() => sort()}>start {<span>{sortDirection === "asc" ? "↑" : "↓"}</span>}</th>
                            <th>end</th>
                            <th>duration [s]</th>
                            {seriesList.map((series, index) => (
                                <th key={index}>{series.name}</th>
                            ))}
                        </tr>
                    </thead>
                    <tbody>
                        {(sortDirection === "asc" ? [...intervals].reverse() : intervals).map((interval, idx) => (
                            <tr key={interval.startTime}>
                                <td style={{ textAlign: "right" }}>{formatDateS(interval.startTime)}</td>
                                <td style={{ textAlign: "right" }}>{formatDateS(interval.endTime)}</td>
                                <td style={{ textAlign: "right" }}>
                                    {calculateDuration(interval.startTime, interval.endTime)}
                                </td>
                                {seriesList.map((series, seriesIdx) => (
                                    <td key={seriesIdx} style={{ textAlign: "right" }}>
                                        {series.values[sortDirection === "asc" ? series.values.length - 1 - idx : idx]}
                                    </td>
                                ))}
                            </tr>
                        ))}
                    </tbody>
                </table>
            </div>
        </div>
    );
}
