import React, { useState, useRef } from "react";
import { gzip } from "pako";
import type { IAPI } from "~/api";

async function compressFile(file: File): Promise<Blob> {
    return new Promise((resolve, reject) => {
        const reader = new FileReader();
        reader.onload = function (event) {
            try {
                const result = event.target?.result;
                if (typeof result === "string" || result instanceof ArrayBuffer) {
                    const compressedData = gzip(result);
                    resolve(new Blob([compressedData], { type: "application/gzip" }));
                } else {
                    reject(new Error("File reading resulted in null or unexpected type"));
                }
            } catch (error) {
                reject(error);
            }
        };
        reader.onerror = function (error) {
            reject(error);
        };
        reader.readAsText(file); // Read the file as text for compression
    });
}

export interface ApiEndpointSpec {
    api: IAPI | null | undefined;
    path: string | null | undefined;
    // func: (arg: T) => Promise<X>;
}

export function FileUploadComponent({
    endpoint,
    onFileUpload,
}: {
    endpoint: ApiEndpointSpec;
    onFileUpload?: ((success: boolean) => void) | null | undefined;
}) {
    const fileInputRef = useRef<HTMLInputElement>(null);
    const [file, setFile] = useState<File | null>(null);
    const [isUploading, setIsUploading] = useState<boolean>(false);

    const handleFileChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        const files = event.target.files;
        if (files) {
            setFile(files[0]);
        } else {
            setFile(null);
        }
    };

    const handleSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
        e.preventDefault();
        if (file) {
            setIsUploading(true);
            try {
                const compressedFile = await compressFile(file);
                const formData = new FormData();
                formData.append("file", compressedFile, file.name + ".gz");

                const response = await endpoint.api!.sendPostRequest(endpoint.path!, formData, {
                    headers: {
                        "Content-Type": "multipart/form-data",
                    },
                });
                console.log(response.data);
                setIsUploading(false);
                onFileUpload && onFileUpload(true);
                setFile(null);
                if (fileInputRef.current) {
                    // Reset the file input after successful upload
                    fileInputRef.current.value = "";
                }
                alert("File uploaded successfully");
            } catch (error) {
                console.error("Error compressing or uploading file:", error);
                setIsUploading(false);
                onFileUpload && onFileUpload(false);
                alert("Error uploading file");
            }
        }
    };

    return (
        <div style={{ display: "flex", flexDirection: "row", gap: 5, alignItems: "center" }}>
            {isUploading ? (
                <div className="spinner small-spinner" style={{ paddingLeft: 5, paddingRight: 5 }} />
            ) : (
                <div style={{ width: 20 }}></div>
            )}
            <form onSubmit={handleSubmit}>
                <input
                    type="file"
                    ref={fileInputRef}
                    onChange={handleFileChange}
                    accept=".csv"
                    disabled={isUploading}
                />
                <button type="submit" disabled={!file || isUploading}>
                    Upload File
                </button>
            </form>
        </div>
    );
}
