export function findIndexAfterGivenTimestamp(items: { startTime: string }[], timestamp: string) {
    let left = 0;
    let right = items.length - 1;

    while (left <= right) {
        const mid = Math.floor((left + right) / 2);
        const midTimestamp = items[mid].startTime;

        if (midTimestamp <= timestamp) {
            // Move the search to the right half if the mid item's timestamp is less than or equal
            left = mid + 1;
        } else {
            // Move the search to the left half if the mid item's timestamp is greater
            right = mid - 1;
        }
    }

    // 'left' is now the index of the first item whose startTime is after the given timestamp
    // or items.length if all items are before the given timestamp
    return left < items.length ? left : -1; // Return -1 if no such item exists
}

// // Example usage
// const items = [
//     { startTime: "2023-03-20T12:00:00Z" },
//     { startTime: "2023-03-21T12:00:00Z" },
//     // Assume more items sorted by startTime
// ];

// const timestamp = "2023-03-20T15:00:00Z";
// const index = findIndexAfterGivenTimestamp(items, timestamp);

// console.log(index); // This will log the index of the first item whose startTime is after the timestamp
