// Define TypeScript interface for the item

export type InstantIndex = string[];

export interface NamedInstantIndex {
    name: string;
    values: InstantIndex;
}

export interface Interval {
    startTime: string;
    endTime: string;
}

export type IntervalIndex = Interval[];

export interface NamedIntervalIndex {
    name: string;
    values: IntervalIndex;
}

export interface InstantSeries {
    name: string;
    index: string | InstantIndex;
    values: number[];
}

export interface IntervalSeries {
    name: string;
    index: string | IntervalIndex;
    values: number[];
}

export interface Series {
    name: string;
    index: InstantIndex | IntervalIndex;
    values: number[];
}

export interface SeriesCollection {
    indexes: (NamedInstantIndex | NamedIntervalIndex)[];
    series: (InstantSeries | IntervalSeries)[];
}

export interface Subject {
    id: string;
    name: string;

    seriesCollection: SeriesCollection;
    series: Series[];
}

export function buildSeries(seriesCollection: SeriesCollection, key: string): Series | null {
    // convert seriesCollection.series to map (key is name, value is the series)
    const seriesMap = new Map<string, InstantSeries | IntervalSeries>();
    for (const ser of seriesCollection.series) {
        seriesMap.set(ser.name, ser);
    }

    const s = seriesMap.get(key);
    if (!s) {
        return null;
    }

    const indexByName = new Map<string, NamedInstantIndex | NamedIntervalIndex>();
    for (const index of seriesCollection.indexes) {
        indexByName.set(index.name, index);
    }
    if (typeof s.index === "string") {
        const index = indexByName.get(s.index);
        if (index === undefined) {
            //throw new Error(`Index not found: ${s.index}`);
            return null;
        } else {
            return {
                name: key,
                index: index.values,
                values: s.values,
            };
        }
    } else {
        return {
            name: key,
            index: s.index,
            values: s.values,
        };
    }
}
