import React, { useCallback, useEffect, useState } from "react";
import { DatacaseCard } from "./DatacaseCard";
import { SubjectList } from "./SubjectList";
import { TimeSeriesChart } from "./TimeSeriesChart";

import type { Datacase, Subject } from "~/model";
import { api } from "~/api/api";
import type { IntervalIndex, Series } from "~/model/Subject";
import { buildSeries, type SeriesCollection } from "~/model/Subject";
import { TimeSeriesTable } from "./TimeSeriesTable";

export function AnalysisPage({ datacase }: { datacase: Datacase }) {
    const [selectedSubject, setSelectedSubject] = useState<Subject | null>(null);
    const [selectedDate, setSelectedDate] = useState<string | null>(null);

    const [subjects, setSubjects] = useState<Subject[] | null>(null);

    const [series, setSeries] = useState<Series[]>([]);

    const [isLoading, setIsLoading] = useState(false);
    const [isLoadingSubjects, setIsLoadingSubjects] = useState(false);

    useEffect(() => {
        const load = async () => {
            setIsLoadingSubjects(true);
            const { data } = await api.sendGetRequest(`subjects/${datacase.id}`);
            console.log(data);
            setSubjects(data as Subject[]);
            setIsLoadingSubjects(false);
        };
        load();
    }, [datacase]);

    useEffect(() => {
        async function load() {
            if (selectedSubject) {
                setIsLoading(true);
                const { data } = await api.sendGetRequest(`series/${datacase.id}/${selectedSubject.id}`);
                selectedSubject.seriesCollection = data as SeriesCollection;
                selectedSubject.series = [];
                for (const s of selectedSubject.seriesCollection.series) {
                    const series = buildSeries(selectedSubject.seriesCollection, s?.name);
                    if (series) {
                        selectedSubject.series.push(series);
                    }
                }
                setSeries(selectedSubject.series);
            } else {
                setSeries([]);
            }
            setIsLoading(false);
        }

        console.log("loading");
        load();
    }, [selectedSubject, datacase]);

    const onSelectedPoint = useCallback((x: string) => {
        setSelectedDate(x);
    }, []);

    return (
        <div style={{ display: "flex", flexDirection: "row", width: "100%", height: "100%", padding: 0 }}>
            <div style={{ width: 300, flexShrink: 0 }}>
                <DatacaseCard datacase={datacase} />
            </div>
            <div style={{ width: 120, flexShrink: 0 }}>
                {isLoadingSubjects ? (
                    <div style={{ display: "flex", justifyContent: "center", alignItems: "center", height: 100 }}>
                        <div className="spinner small-spinner" />
                    </div>
                ) : (
                    <SubjectList
                        subjects={subjects || []}
                        onRowSelected={(subject) => {
                            setSelectedSubject(subject);
                        }}
                    />
                )}
            </div>
            <div style={{ flexGrow: 1 }}>
                {isLoading ? (
                    <div style={{ display: "flex", justifyContent: "center", alignItems: "center", height: 200 }}>
                        <div className="spinner" />
                    </div>
                ) : selectedSubject ? (
                    <div style={{ paddingLeft: 1, display: "flex", flexDirection: "column", height: "100%" }}>
                        <TimeSeriesChart series={series} onSelectedPoint={onSelectedPoint} />
                        <TimeSeriesTable
                            intervals={series.length > 0 ? (series[0].index as IntervalIndex) : []}
                            seriesList={series}
                            selectedDate={selectedDate}
                        />
                    </div>
                ) : (
                    <div>Select a detector</div>
                )}
            </div>
        </div>
    );
}
