import type { AxiosPromise, AxiosRequestConfig } from "axios";

import { Auth } from "./auth";
import { LowLevel } from "./lowlevel";
import type { AuthenticatorFunction, IAuthenticatedUserInfo } from "./types";

export interface IAPI {
    setAuthenticator: (value: AuthenticatorFunction) => void;
    sendGetRequest: (url: string, config?: AxiosRequestConfig) => AxiosPromise<unknown>;
    sendPostRequest: (url: string, data?: unknown, config?: AxiosRequestConfig) => AxiosPromise<unknown>;
    sendPutRequest: (url: string, data?: unknown, config?: AxiosRequestConfig) => AxiosPromise<unknown>;
    sendDeleteRequest: (url: string, config?: AxiosRequestConfig) => AxiosPromise<unknown>;
    callForLogin: (user: string, password: string) => AxiosPromise<unknown>;
    callForLogout: () => AxiosPromise<unknown>;
    callForGetAuthenticatedUserName: () => Promise<string>;
    callForGetAuthenticationInfo: () => Promise<IAuthenticatedUserInfo>;
}

/**
 * This is some kind of facade over Auth and LowLevel (?)
 *
 * creates LowLevel
 *
 * - setAuthenticator...why? ... exposed
 * - call for login / logout / authentication info / authenticated user
 * - send get / post / put / delete request
 */
export class API {
    private readonly _lowLevel: LowLevel;

    private readonly _auth: Auth;

    public constructor(rootUrl: string) {
        console.log("API constructor - rootUrl", rootUrl);
        this._lowLevel = new LowLevel(rootUrl);
        this._auth = new Auth(this._lowLevel);
    }

    public setAuthenticator = (value: AuthenticatorFunction): void => this._lowLevel.setAuthenticator(value);

    public readonly sendGetRequest = (url: string, config?: AxiosRequestConfig): AxiosPromise<unknown> =>
        this._lowLevel.sendGetRequest(url, config);

    public readonly sendPostRequest = (
        url: string,
        data?: unknown,
        config?: AxiosRequestConfig
    ): AxiosPromise<unknown> => this._lowLevel.sendPostRequest(url, data, config);

    public readonly sendPutRequest = (
        url: string,
        data?: unknown,
        config?: AxiosRequestConfig
    ): AxiosPromise<unknown> => this._lowLevel.sendPutRequest(url, data, config);

    public readonly sendDeleteRequest = (url: string, config?: AxiosRequestConfig): AxiosPromise<unknown> =>
        this._lowLevel.sendDeleteRequest(url, config);

    public readonly callForLogin = (user: string, password: string): AxiosPromise<unknown> =>
        this._auth.callForLogin(user, password);

    public readonly callForLogout = (): AxiosPromise<unknown> => this._auth.callForLogout();

    public readonly callForGetAuthenticatedUserName = (): Promise<string> =>
        this._auth.callForGetAuthenticatedUserName();

    public readonly callForGetAuthenticationInfo = (): Promise<IAuthenticatedUserInfo> =>
        this._auth.callForGetAuthenticationInfo();
}

export const rootUrl: string | undefined = process.env.API_ROOT_URL;
if (rootUrl === undefined) {
    throw new Error("Missing root URL environment config for the API calls.");
}

export const api = new API(rootUrl);
