import React, { useState, useEffect } from "react";

import type { Subject } from "../model";

export function SubjectList({
    subjects,
    onRowSelected,
}: {
    subjects: Subject[];
    onRowSelected: (item: Subject) => void;
}) {
    return (
        <div className="card" style={{ height: "100%", padding: 0 }}>
            <div className="table-log-container" style={{ padding: 0, margin: 0 }}>
                <table className="table-log" style={{ marginTop: 0, padding: 0 }}>
                    <thead>
                        <tr>
                            <th>Detector</th>
                        </tr>
                    </thead>
                    <tbody>
                        {subjects.map((item, index) => (
                            <tr key={item.name} onClick={() => onRowSelected(item)} style={{ cursor: "pointer" }}>
                                <td>{item.name}</td>
                            </tr>
                        ))}
                    </tbody>
                </table>
            </div>
        </div>
    );
}
