import React, { useState, useEffect } from "react";
import { formatDate } from "../dateutil";
import ReactTimeAgo from "react-time-ago";

import { api } from "../api/api";

import type { Datacase as Item } from "../model";
import { FileUploadComponent } from "./FileUpload";

const path = "datacases";

type Column = "name" | "created" | "description";

export function DatacasesTable({ onRowSelected }: { onRowSelected: (item: Item) => void }) {
    const [items, setItems] = useState<Item[]>([]);
    const [sortColumn, setSortColumn] = useState<Column>("name");
    const [sortDirection, setSortDirection] = useState<"asc" | "desc">("asc");

    const fetchItems = async () => {
        try {
            // const response = await axios.get("/api/items"); // Replace '/api/items' with your actual endpoint
            const response = await api.sendGetRequest(path);
            setItems(response.data as Item[]);
        } catch (error) {
            console.error("There was an error fetching the items:", error);
        }
    };

    useEffect(() => {
        fetchItems();
    }, []);

    // TODO use callback
    const sortByColumn = (columnName: Column) => {
        const isAsc = sortColumn === columnName && sortDirection === "asc";
        setSortColumn(columnName);
        setSortDirection(isAsc ? "desc" : "asc");
        const sortedItems = [...items].sort((a: Item, b: Item) => {
            if (a[columnName] < b[columnName]) return isAsc ? -1 : 1;
            if (a[columnName] > b[columnName]) return isAsc ? 1 : -1;
            return 0;
        });
        setItems(sortedItems);
    };

    function onFileUpload(success: boolean) {
        if (success) {
            fetchItems();
        }
    }

    return (
        <div style={{ width: "100%" }}>
            <div
                style={{
                    display: "flex",
                    justifyContent: "end",
                    alignItems: "center",
                    height: 30,
                }}
            >
                <div>Add datacase: </div>
                <FileUploadComponent endpoint={{ api: api, path: "datacases" }} onFileUpload={onFileUpload} />
            </div>
            <div className="table-log-container">
                <table className="table-log">
                    <thead>
                        <tr>
                            <th onClick={() => sortByColumn("name")}>
                                Name {sortColumn === "name" && <span>{sortDirection === "asc" ? "↑" : "↓"}</span>}
                            </th>
                            <th onClick={() => sortByColumn("created")}>
                                Created {sortColumn === "created" && <span>{sortDirection === "asc" ? "↑" : "↓"}</span>}
                            </th>
                            <th>Description</th>
                            <th>Notes</th>
                        </tr>
                    </thead>
                    <tbody>
                        {items.map((item, index) => (
                            <tr key={item.name} onClick={() => onRowSelected(item)} style={{ cursor: "pointer" }}>
                                <td>{item.name}</td>
                                <td>{formatDate(item.created)}</td>
                                <td>{item.description}</td>
                                {/* <td>{item.notes}</td> */}
                                <td></td>
                            </tr>
                        ))}
                    </tbody>
                </table>
            </div>
        </div>
    );
}
