import { DateTime } from "luxon";
import React, { useState } from "react";
import { api } from "~/api/api";
import { formatDate } from "~/dateutil";
import type { Datacase, Note } from "~/model";

export function DatacaseCard({ datacase }: { datacase: Datacase }) {
    const [notes, setNotes] = useState<Note[]>(datacase.notes);
    const [noteText, setNoteText] = useState("");

    async function addNote() {
        if (!noteText) {
            return;
        }

        const newNote = {
            text: noteText,
            datacase_id: datacase.id,
        };

        const result = await api.sendPostRequest("notes", newNote);
        console.log(result);
        if (result.status === 200) {
            const newNote = result.data as Note;
            setNotes([...notes, newNote]);
            setNoteText("");
        }
    }

    return (
        <div className="card" style={{ height: "100%", padding: 5 }}>
            <table style={{ textAlign: "left" }}>
                <tbody>
                    <tr>
                        <td>Datacase:</td>
                        <td>{datacase.name}</td>
                    </tr>
                    <tr>
                        <td>Created:</td>
                        <td>{formatDate(datacase.created)}</td>
                    </tr>
                    <tr>
                        <td>Description:</td>
                        <td>{datacase.description}</td>
                    </tr>
                </tbody>
            </table>
            <div style={{ marginTop: "20px" }}>
                <p>Notes:</p>
                <div
                    style={{
                        maxHeight: "200px",
                        overflowY: "auto",
                        // padding: "10px",
                        // border: "1px solid #ccc",
                        // borderRadius: "8px",
                    }}
                >
                    {notes.map((note) => (
                        <div key={note.created} style={{ marginBottom: "10px", backgroundColor: "#FFFFE0" }}>
                            <div>
                                {note.user}{" "}
                                <span style={{ fontWeight: "normal", fontSize: "0.8rem" }}>
                                    {/* https://github.com/moment/luxon/blob/master/docs/formatting.md */}
                                    {formatDate(note.created)}
                                </span>
                            </div>
                            <div>{note.text}</div>
                        </div>
                    ))}
                </div>
                <div style={{ marginTop: "10px" }}>
                    <textarea
                        value={noteText}
                        onChange={(e) => setNoteText(e.target.value)}
                        placeholder="Add a note..."
                        style={{ width: "100%", height: "100px", padding: 0, margin: 0 }}
                    ></textarea>
                    <button onClick={addNote} style={{ marginTop: "10px" }}>
                        Add Note
                    </button>
                </div>
            </div>
        </div>
    );
}
