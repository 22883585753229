import React, { useEffect, useState } from "react";
import LoginDialog from "./LoginDialog";

import { observer } from "mobx-react-lite";
import { authStore } from "./auth/authStore";

import { api } from "./api/api";
import { AnalysisPage, DatacasesTable } from "./components";

import "./App.css";
import type { Datacase } from "./model";
import type { MenuItem } from "./Menu";
import { Menu } from "./Menu";

function configureAPI(): void {
    api.setAuthenticator(authStore.authenticate);
}

configureAPI();

const App: React.FC = observer(() => {
    const [displayedViewName, setDisplayedViewName] = useState<string>("datacases");
    const [selectedDatacase, setSelectedDatacase] = useState<Datacase | null>(null);

    const handleLogout = async () => {
        await api.callForLogout();
        authStore.logout();
    };

    useEffect(() => {
        const verifyLogin = async () => {
            try {
                await api.callForGetAuthenticationInfo();
                authStore.login();
            } catch {
                authStore.logout();
            }
        };
        verifyLogin();
    }, []);

    async function onDatacaseSelected(item: Datacase) {
        setSelectedDatacase(item);
        setDisplayedViewName("analysis");
    }

    const menuItems: MenuItem[] = [
        {
            key: "datacases",
            label: "Datacases",
            action: () => setDisplayedViewName("datacases"),
            enabled: true,
        },
        {
            key: "analysis",
            label: "Analysis",
            action: () => setDisplayedViewName("analysis"),
            enabled: true,
        },
    ];

    if (!authStore.isLoggedIn) {
        console.log("rendering login dialog");
        return <LoginDialog callLogin={api.callForLogin} />;
    } else {
        console.log("rendering app");
        return (
            <div className="app">
                <div className="upper-bar">
                    <Menu
                        items={menuItems}
                        selected={displayedViewName}
                        onSelect={(item) => setDisplayedViewName(item.key)}
                    />
                    <div style={{ flexBasis: 10, flexGrow: 10 }}></div>
                    <div className="small-text">version 22.3.2024 19:20</div>
                    <div>
                        <button onClick={handleLogout} style={{ maxWidth: 80 }}>
                            logout
                        </button>
                    </div>
                </div>

                <div
                    className="page"
                    style={{ display: "flex", gap: 0, flexDirection: "column", width: "100%", height: "100%" }}
                >
                    {displayedViewName === "datacases" ? (
                        <DatacasesTable onRowSelected={onDatacaseSelected} />
                    ) : (
                        <div></div>
                    )}
                    {displayedViewName === "analysis" ? (
                        selectedDatacase === null ? (
                            <div>Select a datacase</div>
                        ) : (
                            <AnalysisPage datacase={selectedDatacase} />
                        )
                    ) : (
                        <div></div>
                    )}
                </div>
            </div>
        );
    }
});

export default App;
