import React from "react";

export interface MenuItem {
    key: string;
    label: string;
    action: () => void;
    enabled: boolean;
}

interface MenuProps {
    items: MenuItem[];
    selected: string | null;
    onSelect: (name: MenuItem) => void;
}

export const Menu: React.FC<MenuProps> = ({ items, selected, onSelect }) => {
    return (
        <div className="main-menu">
            {items.map((item) => (
                <div
                    key={item.key}
                    className={item.key === selected ? "main-menu-div main-menu-item-selected" : "main-menu-div"}
                    onClick={() => {
                        if (item.enabled) {
                            onSelect(item);
                            item.action();
                        }
                    }}
                >
                    {item.label}
                </div>
            ))}
        </div>
    );
};
