import React, { useState, useEffect, useRef } from "react";
import Plot from "react-plotly.js";
import type { Data, PlotMouseEvent } from "plotly.js";

import type { Interval, IntervalIndex, Series } from "~/model/Subject";
import type { InstantIndex } from "~/model/Subject";

function isIntervalIndex(index: InstantIndex | IntervalIndex): index is IntervalIndex {
    return index.length > 0 && (index[0] as Interval).startTime !== undefined;
}

function prepareData(series: Series) {
    // return {
    //     x: ["2024-01-01", "2024-02-02", "2024-12-03"],
    //     y: [10, 15, 13],
    // };

    if (isIntervalIndex(series.index)) {
        const index = series.index as IntervalIndex;
        const x = index.map((i) => i.startTime);
        return { x, y: series.values };
    } else {
        const x = series.index as InstantIndex;
        return { x, y: series.values };
    }
}

/*
    %Y: Year with century as a decimal number (e.g., "2023").
    %y: Year without century as a zero-padded decimal number (e.g., "23" for 2023).
    %m: Month as a zero-padded decimal number (e.g., "01" for January).
    %B: Full month name (e.g., "January").
    %b or %h: Abbreviated month name (e.g., "Jan").
    %d: Day of the month as a zero-padded decimal number (e.g., "04").
    %j: Day of the year as a zero-padded decimal number (e.g., "365" for December 31).
    %A: Full weekday name (e.g., "Monday").
    %a: Abbreviated weekday name (e.g., "Mon").
    %H: Hour (24-hour clock) as a zero-padded decimal number (e.g., "14").
    %I: Hour (12-hour clock) as a zero-padded decimal number (e.g., "02").
    %p: Locale’s equivalent of either AM or PM.
    %M: Minute as a zero-padded decimal number (e.g., "05").
    %S: Second as a zero-padded decimal number (e.g., "09").
    %f: Microsecond as a decimal number, zero-padded on the left (e.g., "000999").
    %Z: Time zone name (e.g., "UTC", "EST").
    %z: UTC offset in the form +HHMM or -HHMM (e.g., "-0400").
    %%: A literal "%" character.
*/
export function TimeSeriesChart({
    series,
    onSelectedPoint,
}: {
    series: Series[];
    onSelectedPoint: (x: string) => void;
}) {
    const [plotWidth, setPlotWidth] = useState<number>(0);
    const containerRef = useRef<HTMLDivElement>(null);

    const [selectedX, setSelectedX] = useState<string | number | null>(null);

    console.log("series TSC:", series);
    const plotData: Data[] = series.map((s) => ({
        ...prepareData(s),
        name: s.name,
    }));

    // Function to update plot width
    const updatePlotWidth = () => {
        // Use the container's width instead of window.innerWidth
        if (containerRef.current) {
            console.log("containerRef.current.offsetWidth", containerRef.current.offsetWidth);
            setPlotWidth(containerRef.current.offsetWidth);
        }
    };

    // Update plot width on window resize and mount
    useEffect(() => {
        updatePlotWidth(); // Update on mount
        window.addEventListener("resize", updatePlotWidth); // Add resize listener

        // Cleanup
        return () => {
            window.removeEventListener("resize", updatePlotWidth);
        };
    }, []);

    const layout = {
        title: "Traffic intensity",
        xaxis: {
            type: "date" as const,
            title: "Time",
            tickformatstops: [
                {
                    dtickrange: [null, 1000] as [null | number, number],
                    value: "%H:%M:%S.%L",
                },
                {
                    dtickrange: [1000, 60000] as [number, number],
                    value: "%a %e. %b %H:%M:%S",
                },
                {
                    dtickrange: [60000, 3600000] as [number, number],
                    value: "%a %e. %b %H:%M",
                },
                {
                    dtickrange: [3600000, 86400000] as [number, number],
                    value: "%a %e. %b %H:%M",
                },
                {
                    dtickrange: [86400000, 604800000] as [number, number],
                    value: "%a %e. %b",
                },
                {
                    dtickrange: [604800000, "M1"] as [number, string],
                    value: "%a %e. %b",
                },
                {
                    dtickrange: ["M1", "M12"] as [string, string],
                    value: "%b '%y",
                },
                {
                    dtickrange: ["M12", null] as [string, null],
                    value: "%Y",
                },
            ],
        },
        // xaxis2: {
        //     type: "date" as const,
        //     title: "Time2" as const,
        //     overlaying: "x" as const,
        //     side: "top" as const,
        //     tickformatstops: [
        //         {
        //             dtickrange: [null, 1000] as [null | number, number],
        //             value: "%H:%M:%S.%L",
        //         },
        //         {
        //             dtickrange: [1000, 60000] as [number, number],
        //             value: "%H:%M:%S",
        //         },
        //         {
        //             dtickrange: [60000, 3600000] as [number, number],
        //             value: "%H:%M",
        //         },
        //         {
        //             dtickrange: [3600000, 86400000] as [number, number],
        //             value: "%e. %b",
        //         },
        //         {
        //             dtickrange: [86400000, 604800000] as [number, number],
        //             value: "%e. %b",
        //         },
        //         {
        //             dtickrange: [604800000, "M1"] as [number, string],
        //             value: "%e. %b",
        //         },
        //         {
        //             dtickrange: ["M1", "M12"] as [string, string],
        //             value: "%b '%y",
        //         },
        //         {
        //             dtickrange: ["M12", null] as [string, null],
        //             value: "%Y",
        //         },
        //     ],
        // },
        yaxis: {
            title: "Value",
            fixedrange: true,
        },
        autosize: true,
        margin: {
            l: 50,
            r: 50,
            b: 100,
            t: 50,
            pad: 4,
        },
    };

    // Function to handle click event on datapoints
    const handleDataPointClick = (eventData: Readonly<PlotMouseEvent>) => {
        const xValue = (eventData.points[0].x as string).replace(" ", "T");
        setSelectedX(xValue);
        console.log("Selected x-value:", xValue);
        onSelectedPoint(xValue);
    };

    return (
        <div ref={containerRef} style={{ width: "100%" }}>
            <Plot
                data={plotData}
                layout={{ ...layout, width: plotWidth, autosize: true }}
                useResizeHandler={true}
                config={{ scrollZoom: true }}
                onClick={handleDataPointClick}
            />
        </div>
    );
}
