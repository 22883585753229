import TimeAgo from "javascript-time-ago";
import en from "javascript-time-ago/locale/en";
import { DateTime } from "luxon";

// Initialize the locale data
TimeAgo.addLocale(en);

// export function formatDate(dateString: string) {
//     const date = new Date(dateString);
//     const day = date.getDate().toString().padStart(2, "0");
//     const month = (date.getMonth() + 1).toString().padStart(2, "0"); // Month is 0-indexed
//     const hours = date.getHours().toString().padStart(2, "0");
//     const minutes = date.getMinutes().toString().padStart(2, "0");

//     return `${day}.${month} ${hours}:${minutes}`;
// }

export function formatDate(dateString: string, format = "ccc dd.MM.yyyy HH:mm ZZ") {
    const date = DateTime.fromISO(dateString);
    return date.toFormat(format);
}

export function formatDateS(dateString: string) {
    return formatDate(dateString, "ccc dd.MM.yyyy HH:mm:ss ZZ");
}
