import React from "react";

import { createRoot } from "react-dom/client";

import App from "./App";
import "./index.css";

const root = createRoot(document.getElementById("react-root-container") as HTMLElement);

root.render(
    <React.StrictMode>
        <App />
    </React.StrictMode>
);
